<template>
  <!-- <HelloWorld /> -->
  <v-row
    align="center"
    justify="center"
    no-gutters
  >
    <!-- 중요: ESC 키를 누르면 팝업이 닫히므로 persistent 속성을 주어 모달로 만들어 준다. -->
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      hide-overlay
    >

      <!-- 크롬이 아닌 경우의 경고 영역: sm 이하 에서는 안보임 hidden-sm-and-down -->
      <section v-if="browserNotChrome" class="d-none d-sm-block ma-0" style="background-color: #fff">
        <v-footer
          padless
          color="red lighten-4"
        >
          <v-row
            justify="center"
            no-gutters
          >
            <v-icon large color="error" class="my-4 mr-2">mdi-alert-circle-outline</v-icon>
            <span
              class="error--text mt-5"
              style="font-family: Roboto;font-size: 20px;font-weight: 600;"
            >
              LAWORK는 크롬 브라우저에 최적화되어 있습니다. 크롬을 활용해주시기 바랍니다.
            </span>
            <v-btn
              fab x-small elevation="1" color="error" class="ml-2 mt-4"
              @click="goGoogleInstall"
            >
              <v-icon small>mdi-launch</v-icon>
            </v-btn>
          </v-row>
        </v-footer>
      </section>
      <!--/ 크롬이 아닌 경우의 경고 영역 -->

      <!-- 최상단: 좌측에 로워크 로고 1: lg 보다 커야 나타난다 -->
      <section id="topArea_box" class="d-none d-lg-block ma-0" style="background-color: #fff">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col cols="2" class="text-center">
            <v-toolbar dense tile flat></v-toolbar>
          </v-col>
          <v-col cols="8" class="text-center">
            <v-toolbar dense tile flat>
              <img src="@/assets/Home/img/lawork_chr.png">
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-toolbar dense tile flat></v-toolbar>
          </v-col>
        </v-row>
      </section>
      <!--/ 최상단: 좌측에 로워크 로고 -->

      <!-- 상단 좌측 로고와 우측 메뉴 1: lg 보다 커야 나타난다 -->
      <section class="d-none d-lg-block ma-0" style="background-color: #fff">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col cols="2" class="text-center">
            <v-toolbar dark tile flat color="#002450" height="80px"></v-toolbar>
          </v-col>
          <v-col cols="8" class="text-center">
            <v-toolbar dark tile flat color="#002450" height="80px">
              <h1 style="font-family: Roboto;font-size: 40px;font-weight: 900;">
                LAWORK
              </h1>
              <v-spacer></v-spacer>
              <!-- 소개자료 버튼: lg 보다 커야 나타난다 -->
              <v-btn
                x-large
                text
                rounded
                style="font-size: 18px;font-weight: 500;"
                @click.prevent="goCustomCenter"
              >
                소개자료
              </v-btn>
              <!-- 가격안내 버튼: lg 보다 커야 나타난다 -->
              <v-btn
                x-large
                text
                rounded
                style="font-size: 18px;font-weight: 500;"
                @click.prevent="goPriceBox"
              >
                가격안내
              </v-btn>
              <!-- 중요: 이미 로그인 되어있다! 일정으로 보낸다. -->
              <template v-if="isLogined">
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    x-large
                    :color="hover ? 'warning' : 'pink'"
                    :text="hover"
                    rounded
                    style="font-size: 18px;font-weight: 500;"
                    @click="$router.push('/schedule')"
                  >
                    사건관리 Start
                    <v-icon right class="ml-2">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <!-- 중요: 로그아웃 시! 구글 로그인 태우기 -->
              <template v-else>
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    x-large
                    :color="hover ? 'warning' : 'pink'"
                    :text="hover"
                    rounded
                    style="font-size: 18px;font-weight: 500;"
                    @click="logInProc"
                  >
                    사건관리 Start
                    <v-icon right class="ml-2">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-hover>
              </template>
            </v-toolbar>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-toolbar dark tile flat color="#002450" height="80px"></v-toolbar>
          </v-col>
        </v-row>
      </section>
      <!--/ 상단 좌측 로고와 우측 메뉴 1: lg 보다 커야 나타난다 -->
      <!-- 상단 좌측 로고와 우측 메뉴 2: lg 보다 작아야 나타난다 -->
      <section class="d-lg-none ma-0" style="background-color: #fff">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col cols="1" class="text-center">
            <v-toolbar dark tile flat color="#002450" height="80px"></v-toolbar>
          </v-col>
          <v-col cols="10">
            <v-toolbar dark tile flat color="#002450" height="80px">
              <v-spacer></v-spacer>
              <h1 style="font-family: Roboto;font-size: 40px;font-weight: 900;">
                LAWORK
              </h1>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-col>
          <v-col cols="1" class="text-center">
            <v-toolbar dark tile flat color="#002450" height="80px"></v-toolbar>
          </v-col>
        </v-row>
      </section>
      <!--/ 상단 좌측 로고와 우측 메뉴 2: lg 보다 작아야 나타난다 -->

      <!-- 크롬이 아닌 경우의 경고 영역: d-sm-none sm 이상으로 커지면 안보임 -->
      <section v-if="browserNotChrome" class="d-sm-none ma-0" style="background-color: #fff">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col cols="12" class="text-center">
            <v-card
              :elevation="0"
              class="text-center error--text py-6"
              tile
              color="red lighten-4"
            >
              <v-icon large color="error" class="mr-1">mdi-alert-circle-outline</v-icon>
              LAWORK는 크롬 브라우저에 최적화되어 있습니다.<br>크롬을 활용해주시기 바랍니다.
              <v-btn
                icon small color="error" class="mb-1"
                @click="goGoogleInstall"
              >
                <v-icon small>mdi-launch</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </section>
      <!--/ 크롬이 아닌 경우의 경고 영역 -->

      <!-- 메인 이미지 영역: lg 보다 커야 나타난다 -->
      <section class="d-none d-lg-block ma-0" style="background-color: #fff">
        <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-col cols="12" class="text-center py-2">
              <div style="color: #666;font-size: 24px;font-weight: 500;text-align: center;line-height: 90px;">
                <span style="color: #538be6;font-size: 30px;font-weight: 700;vertical-align: -2px;">로데스크</span> 노하우를 집약한 토탈사건관리 그룹웨어 시스템
              </div>
            </v-col>
        </v-row>
        <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-col cols="12" class="text-center">
              <img src="@/assets/Home/img/visual_img.png">
            </v-col>
        </v-row>
      </section>
      <!--/ 메인 이미지 영역 -->

      <!-- 사건관리Start 버튼 영역 -->
      <section class="ma-0" style="background-color: #fff">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col cols="12" xs="12">
            <v-card
              :elevation="0"
              class="text-center py-6"
            >
              <!-- 중요: 이미 로그인 되어있다! 일정으로 보낸다. -->
              <template v-if="isLogined">
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    x-large
                    rounded
                    outlined
                    depressed
                    :color="hover ? 'pink lighten-1' : 'info darken-3'"
                    class="mx-2"
                    style="font-size: 22px;font-weight: 500;"
                    @click="$router.push('/schedule')"
                  >
                    사건관리 Start
                    <v-icon right class="ml-2">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <!-- 중요: 로그아웃 시! 구글 로그인 태우기 -->
              <template v-else>
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    x-large
                    rounded
                    outlined
                    depressed
                    :color="hover ? 'pink lighten-1' : 'info darken-3'"
                    class="mx-2"
                    style="font-size: 22px;font-weight: 500;"
                    @click="logInProc"
                  >
                    사건관리 Start
                    <v-icon right class="ml-2">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-hover>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </section>
      <!--/ 사건관리Start 버튼 영역 -->

      <!-- 고객센터 -->
      <section id="customCenter_box" class="ma-0" style="background-color: #fff">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col cols="12" xs="12" md="6" sm="12">
            <!-- lg 보다 커야 나타나는 영역 -->
            <v-card
              :elevation="0"
              tile
              color="#002450"
              class="d-none d-lg-block text-right white--text py-12 pr-8"
              style="font-size: 40px;font-weight: 400;padding: 0 0 20px 70px;"
            >
              <p>
                <v-icon class="mb-1 mr-2" color="white" style="font-size: 45px;font-weight: 400;">mdi-card-account-phone-outline</v-icon>
                고객센터 02-521-7505
              </p>
              <p>
                <v-icon class="mb-1 mr-2" color="white" style="font-size: 45px;font-weight: 400;">mdi-clock-outline</v-icon>
                평일 10:00-18:00
              </p>
            </v-card>
            <!-- lg 보다 작아야 나타나는 영역 -->
            <v-card
              :elevation="0"
              tile
              color="#002450"
              class="d-lg-none text-left white--text py-12 pr-8"
              style="font-size: 30px;font-weight: 400;padding: 0 0 20px 40px;"
            >
              <p>
                <v-icon class="mb-1 mr-2" color="white" style="font-size: 30px;font-weight: 400;">mdi-card-account-phone-outline</v-icon>
                고객센터 02-521-7505
              </p>
              <p>
                <v-icon class="mb-1 mr-2" color="white" style="font-size: 30px;font-weight: 400;">mdi-clock-outline</v-icon>
                평일 10:00-18:00
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" md="6" sm="12">
            <!-- lg 보다 커야 나타나는 영역 -->
            <v-card
              :elevation="0"
              tile
              color="#3b5bcd"
              class="d-none d-lg-block text-left white--text py-6 pl-8"
              style="font-size: 16px;font-weight: 500;line-height: 1.8;"
            >
              <p>
                구글 앱스와 연동된 미래지향적 서비스로 앞서가십시오.<br>
                클라우드 컴퓨팅은 시대의 흐름입니다.<br>
                언제, 어디서이든지 접속만 하면 클라우드 스토리지를 통해 관리할 수 있습니다.<br>
                구글계정만 있다면 아주 쉽습니다.<br>
                한 번만 접속하면 번거로운 절차없이 계속해서 안전하게 로그인 됩니다.<br>
                또한 대화형 인터페이스를 활용한 송무관리는 업무효율성을 극대화시켜줍니다.
              </p>
            </v-card>
            <!-- lg 보다 작아야 나타나는 영역 -->
            <v-card
              :elevation="0"
              tile
              color="#3b5bcd"
              class="d-lg-none text-left white--text py-6 pl-8"
              style="font-size: 14px;font-weight: 400;line-height: 1.8;"
            >
              <p>
                구글 앱스와 연동된 미래지향적 서비스로 앞서가십시오.<br>
                클라우드 컴퓨팅은 시대의 흐름입니다.<br>
                언제, 어디서이든지 접속만 하면 클라우드 스토리지를 통해 관리할 수 있습니다.<br>
                구글계정만 있다면 아주 쉽습니다.<br>
                한 번만 접속하면 번거로운 절차없이 계속해서 안전하게 로그인 됩니다.<br>
                또한 대화형 인터페이스를 활용한 송무관리는 업무효율성을 극대화시켜줍니다.
              </p>
            </v-card>
          </v-col>
        </v-row>
      </section>
      <!--/ 고객센터 -->

      <!-- 서비스 특징 -->
      <section class="ma-0" style="background-color: #fff">
        <v-row no-gutters>
          <v-col cols="12" xs="12" class="mt-4 mb-8">
            <v-container>
              <!-- 1row -->
              <v-row
                no-gutters
                align="start"
                justify="space-around"
              >
                <v-col cols="12" xs="12" md="4">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-around"
                  >
                    <v-card
                      elevation="0"
                      class="transparent"
                    >
                      <v-card-title>
                        <img src="@/assets/Home/img/Scard1.png">
                        <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">소송, 자문, 고객관리를<br>정점으로 하는 정보TREE</span>
                      </v-card-title>
                      <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                        소송, 자문, 고객정보를 상위 메뉴로 하고,<br>
                        진행사항, 업무, 문서, 타임시트,<br>
                        비용, 계약, 수금, 관련사건 등의 하위 정보를<br>
                        배치하여 업무 연속성을 높였습니다.
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-around"
                  >
                    <v-card
                      elevation="0"
                      class="transparent"
                    >
                      <v-card-title>
                        <img src="@/assets/Home/img/Scard2.png">
                        <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">하위 정보의 개별정렬</span>
                      </v-card-title>
                      <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                        하위 정보별(진행사항, 업무, 문서, 타임시트,<br>
                        문서 등) 독립 메뉴와 일정 메뉴를 통해<br>
                        해당 일자의 정보만을 따로 관리할 수 있어<br>
                        업무 집중도를 높였습니다.
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-around"
                  >
                    <v-card
                      elevation="0"
                      class="transparent"
                    >
                      <v-card-title>
                        <img src="@/assets/Home/img/Scard3.png">
                        <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">사건배당 및 업무인수인계</span>
                      </v-card-title>
                      <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                        모든 정보를 업무부서 하에 구조화하여<br>
                        간단한 업무부서원의 배정 조작만으로<br>
                        특정인 및 특정부서의 업무를 일거에<br>
                        인수인계 할 수 있도록 하였습니다.
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>

              <!-- 2row -->
              <v-row
                no-gutters
                align="start"
                justify="space-around"
              >
                <v-col cols="12" xs="12" md="4">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-around"
                  >
                    <v-card
                      elevation="0"
                      class="transparent"
                    >
                      <v-card-title>
                        <img src="@/assets/Home/img/Scard4.png">
                        <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">카테고리의 일괄관리</span>
                      </v-card-title>
                      <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                        관리상태, 항목, 유형 등 조직에서 그동안<br>
                        사용하던 방식대로 카테고리를 조작할 수<br>
                        있도록 하여 업무 연속성과 함께<br>
                        업무 효율성을 향상시켰습니다.
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-around"
                  >
                    <v-card
                      elevation="0"
                      class="transparent"
                    >
                      <v-card-title>
                        <img src="@/assets/Home/img/Scard5.png">
                        <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">문서의 중앙집중</span>
                      </v-card-title>
                      <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                        문서 링크등록방식과 구글드라이브 업로드<br>
                        방식을 선택적으로 사용할 수 있습니다.<br>
                        특히 검증되고 안전한 클라우드 스토리지인<br>
                        구글드라이브는 번거롭게 파일서버를 따로<br>
                        둘 필요가 없어서, 비용 또한 극적으로<br>
                        절약할 수 있습니다. (15GB 무료)<br>
                        또, 로워크에서 문서를 삭제한다고 하더라도<br>
                        관리자 계정의 드라이브에는 해당 문서가<br>
                        그대로 보존됩니다.
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-around"
                  >
                    <v-card
                      elevation="0"
                      class="transparent"
                    >
                      <v-card-title>
                        <img src="@/assets/Home/img/Scard6.png">
                        <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">전자결재</span>
                      </v-card-title>
                      <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                        결재라인에 따른 단계적인 전자결재가<br>
                        이루어지며, 결재결과애 따른 플로워가<br>
                        진행될 수 있도록 조치하였으며,<br>
                        공동결재도 가능하도록 하였습니다.<br>
                        또한, 해당 정보가 지메일과도 연계되어<br>
                        해당 알림을 알 수 있도록 하였습니다.
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>

              <!-- 3row -->
              <v-row
                no-gutters
                align="start"
                justify="space-around"
              >
                <v-col cols="12" xs="12" md="4">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-around"
                  >
                    <v-card
                      elevation="0"
                      class="transparent"
                    >
                      <v-card-title>
                        <img src="@/assets/Home/img/Scard7.png">
                        <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">인사관리</span>
                      </v-card-title>
                      <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                        공유된 적이 있는 모든 조직원의 인사정보<br>
                        및 인사사항, 인사평가를 관리할 수 있도록<br>
                        하여 체계적인 인사관리가 되도록 하였습니다.
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-around"
                  >
                    <v-card
                      elevation="0"
                      class="transparent"
                    >
                      <v-card-title>
                        <img src="@/assets/Home/img/Scard8.png">
                        <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">기타 편의기능</span>
                      </v-card-title>
                      <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                        실무계산기, 상담실예약, 공지사항 알림기능<br>
                        등 부수적인 기능을 통해 업무편의성을<br>
                        높였습니다.
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-row
                    no-gutters
                    align="center"
                    justify="space-around"
                  >
                    <v-card
                      elevation="0"
                      class="transparent"
                    >
                      <v-card-title>
                        <img src="@/assets/Home/img/Scard9.png">
                        <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">구글 앱스의 활용</span>
                      </v-card-title>
                      <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                        구글 계정을 통해 로그인 하는 방식을<br>
                        사용하였기에 드라이브, 메일, 메신저,<br>
                        화상희외를 활용할 수 있도록 구성하였으며,<br>
                        세계 최고 수준의 구글 보안이 적용됩니다.
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>

            </v-container>
          </v-col>
        </v-row>
      </section>
      <!--/ 서비스 특징 -->
      <!-- (기존)서비스 특징 --
      <section class="ma-0" style="background-color: #fff">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col cols="12" xs="12" class="mt-4 mb-8">
            <v-container>
              <v-row
                align="center"
                justify="center"
              >
                <v-col cols="12" xs="12" md="4">
                  <v-card
                    elevation="0"
                    class="transparent"
                  >
                    <v-card-title>
                      <img src="@/assets/Home/img/Scard1.png">
                      <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">소송, 자문, 고객관리를<br>정점으로 하는 정보TREE</span>
                    </v-card-title>
                    <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                      소송, 자문, 고객정보를 상위 메뉴로 하고,<br>
                      진행사항, 업무, 문서, 타임시트,<br>
                      비용, 계약, 수금, 관련사건 등의 하위 정보를<br>
                      배치하여 업무 연속성을 높였습니다.
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-card
                    elevation="0"
                    class="transparent"
                  >
                    <v-card-title>
                      <img src="@/assets/Home/img/Scard2.png">
                      <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">하위 정보의 개별정렬</span>
                    </v-card-title>
                    <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                      하위 정보별(진행사항, 업무, 문서, 타임시트,<br>
                      문서 등) 독립 메뉴와 일정 메뉴를 통해<br>
                      해당 일자의 정보만을 따로 관리할 수 있어<br>
                      업무 집중도를 높였습니다.
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-card
                    elevation="0"
                    class="transparent"
                  >
                    <v-card-title>
                      <img src="@/assets/Home/img/Scard3.png">
                      <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">사건배당 및 업무인수인계</span>
                    </v-card-title>
                    <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                      모든 정보를 업무부서 하에 구조화하여<br>
                      간단한 업무부서원의 배정 조작만으로<br>
                      특정인 및 특정부서의 업무를 일거에<br>
                      인수인계 할 수 있도록 하였습니다.
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                align="start"
                justify="center"
              >
                <v-col cols="12" xs="12" md="4">
                  <v-card
                    elevation="0"
                    class="transparent"
                  >
                    <v-card-title>
                      <img src="@/assets/Home/img/Scard4.png">
                      <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">카테고리의 일괄관리</span>
                    </v-card-title>
                    <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                      관리상태, 항목, 유형 등 조직에서 그동안<br>
                      사용하던 방식대로 카테고리를 조작할 수<br>
                      있도록 하여 업무 연속성과 함께<br>
                      업무 효율성을 향상시켰습니다.
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-card
                    elevation="0"
                    class="transparent"
                  >
                    <v-card-title>
                      <img src="@/assets/Home/img/Scard5.png">
                      <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">문서의 중앙집중</span>
                    </v-card-title>
                    <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                      문서 링크등록방식과 구글드라이브 업로드<br>
                      방식을 선택적으로 사용할 수 있습니다.<br>
                      특히 검증되고 안전한 클라우드 스토리지인<br>
                      구글드라이브는 번거롭게 파일서버를 따로<br>
                      둘 필요가 없어서, 비용 또한 극적으로<br>
                      절약할 수 있습니다. (15GB 무료)<br>
                      또, 로워크에서 문서를 삭제한다고 하더라도<br>
                      관리자 계정의 드라이브에는 해당 문서가<br>
                      그대로 보존됩니다.
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-card
                    elevation="0"
                    class="transparent"
                  >
                    <v-card-title>
                      <img src="@/assets/Home/img/Scard6.png">
                      <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">전자결재</span>
                    </v-card-title>
                    <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                      결재라인에 따른 단계적인 전자결재가<br>
                      이루어지며, 결재결과애 따른 플로워가<br>
                      진행될 수 있도록 조치하였으며,<br>
                      공동결재도 가능하도록 하였습니다.<br>
                      또한, 해당 정보가 지메일과도 연계되어<br>
                      해당 알림을 알 수 있도록 하였습니다.
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                align="start"
                justify="center"
              >
                <v-col cols="12" xs="12" md="4">
                  <v-card
                    elevation="0"
                    class="transparent"
                  >
                    <v-card-title>
                      <img src="@/assets/Home/img/Scard7.png">
                      <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">인사관리</span>
                    </v-card-title>
                    <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                      공유된 적이 있는 모든 조직원의 인사정보<br>
                      및 인사사항, 인사평가를 관리할 수 있도록<br>
                      하여 체계적인 인사관리가 되도록 하였습니다.
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-card
                    elevation="0"
                    class="transparent"
                  >
                    <v-card-title>
                      <img src="@/assets/Home/img/Scard8.png">
                      <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">기타 편의기능</span>
                    </v-card-title>
                    <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                      실무계산기, 상담실예약, 공지사항 알림기능<br>
                      등 부수적인 기능을 통해 업무편의성을<br>
                      높였습니다.
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-card
                    elevation="0"
                    class="transparent"
                  >
                    <v-card-title>
                      <img src="@/assets/Home/img/Scard9.png">
                      <span class="pl-2" style="font-size: 18px;font-weight: 600;color: #538be6;">구글 앱스의 활용</span>
                    </v-card-title>
                    <v-card-text class="text-left text-body-2" style="font-size: 13px;line-height: 1.8;color: #4a5266;">
                      구글 계정을 통해 로그인 하는 방식을<br>
                      사용하였기에 드라이브, 메일, 메신저,<br>
                      화상희외를 활용할 수 있도록 구성하였으며,<br>
                      세계 최고 수준의 구글 보안이 적용됩니다.
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </section>
      !--/ 서비스 특징 -->

      <!-- 가격안내 1 -->
      <!-- lg 보다 커야 나타나는 테이블 영역 -->
      <section id="price_box" class="d-none d-lg-block text-center ma-0 mainprice" style="background-color: #eef1f5">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-card
            :elevation="0"
            class="text-center py-8"
            tile
            color="#eef1f5"
          >
            <h1 class="mb-5" style="font-family: Roboto;font-size: 30px;font-weight: 700;color: #4a5266;line-height: 100%;">가격안내</h1>
            <v-row
              no-gutters
              align="center"
              justify="center"
            >
              <v-col cols="12">
                <table>
                  <colgroup>
                    <col style="width: 25%;">
                    <col style="width: 25%;">
                    <col style="width: 25%;">
                    <col style="width: 25%;">
                  </colgroup>
                  <thead>
                    <tr>
                      <th>이용자수</th>
                      <th>월이용료</th>
                      <th>할인</th>
                      <th>기타</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>5인 이하</td>
                      <td>70,000</td>
                      <td rowspan="5" class="lh">첫 결제시 - 기간 2배 적용<br>6개월 - 5%<br>12개월 - 10%</td>
                      <td rowspan="5" class="line lh">無 설치비<br>無 서버비<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;無 업데이트비</td>
                    </tr>
                    <tr>
                      <td>10인 이하</td>
                      <td>120,000</td>
                    </tr>
                    <tr>
                      <td>15인 이하</td>
                      <td>160,000</td>
                    </tr>
                    <tr>
                      <td>20인 이하</td>
                      <td>190,000</td>
                    </tr>
                    <tr>
                      <td>맞춤형</td>
                      <td>별도 견적</td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
            >
              <v-col cols="3"></v-col>
              <v-col class="text-left">
                <p>
                  단, 1) 구글드라이브 확장 비용은 별도입니다.  ※ 구글드라이브에서 직접 결제<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  2) 보유사건수 10,000건 이상인 경우 - 독자 서버 활용으로 인해 상기 가격표는 적용되지 않습니다.
                </p>
              </v-col>
              <v-col cols="3"></v-col>
            </v-row>
          </v-card>
        </v-row>
      </section>
      <!--/ 가격안내 -->

      <!-- 가격안내 2 -->
      <!-- lg 보다 작아야 나타나는 테이블 영역 -->
      <section id="price_box_s" class="d-lg-none text-center ma-0 mainprice" style="background-color: #eef1f5">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-card
            flat
            width="90%"
            color="#eef1f5"
            class="text-center py-8"
          >
            <h1 class="mb-5" style="font-family: Roboto;font-size: 30px;font-weight: 700;color: #4a5266;line-height: 100%;">가격안내</h1>
            <v-row
              no-gutters
              align="center"
              justify="center"
            >
              <table>
                <colgroup>
                  <col style="width: 50%;">
                  <col style="width: 50%;">
                </colgroup>
                <thead>
                  <tr>
                    <th>이용자수</th>
                    <th>월이용료</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>5인 이하</td>
                    <td>70,000</td>
                  </tr>
                  <tr>
                    <td>10인 이하</td>
                    <td>120,000</td>
                  </tr>
                  <tr>
                    <td>15인 이하</td>
                    <td>160,000</td>
                  </tr>
                  <tr>
                    <td>20인 이하</td>
                    <td>190,000</td>
                  </tr>
                  <tr>
                    <td>맞춤형</td>
                    <td>별도 견적</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <colgroup>
                  <col style="width: 25%;">
                  <col style="width: 75%;">
                </colgroup>
                <tbody>
                  <tr>
                    <th>할인</th>
                    <td class="lh">
                      첫 결제시 기간 2배 적용<br>6개월 - 5%<br>12개월 - 10%
                    </td>
                  </tr>
                  <tr>
                    <th>기타</th>
                    <td class="line lh">
                      無 설치비<br>無 서버비<br>無 업데이트비
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
            >
              <v-col cols="12" class="text-left px-2 pt-5">
                <p>
                  단,<br>
                  1) 구글드라이브 확장 비용은 별도입니다. ※ 구글드라이브에서 직접 결제<br>
                  2) 보유사건수 10,000건 이상인 경우 - 독자 서버 활용으로 인해<br>&nbsp;&nbsp;&nbsp;&nbsp;
                  상기 가격표는 적용되지 않습니다.
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </section>

      <!-- [주석처리] 찾아오시는 길/회사소개 --
      <section class="ma-0" style="background-color: #fff">
        <v-container>
          <v-row
            no-gutters
            align="start"
            justify="center"
          >
            <v-col cols="12" xs="12" sm="4" offset-sm="1">
              <v-card
                elevation="0"
                class="transparent"
              >
                <v-card-title class="text-center">
                  <div class="text-h5">
                    <v-icon class="text--primary">mdi-office-building</v-icon>
                    회사 소개
                  </div>
                </v-card-title>
                <v-card-text>
                  (주)현우엘앤씨는 대한민국의 법률솔루션 제작 업체입니다.<br>
                  검증되고 신뢰성있는 제품을 세상에 내놓기 위해 항상 노력하겠습니다.
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="4" offset-sm="1">
              <v-card
                elevation="0"
                class="transparent"
              >
                <v-card-title class="text-center">
                  <div class="text-h5">
                    <v-icon class="text--primary">mdi-contacts</v-icon>
                    찾아오시는 길
                  </div>
                </v-card-title>
                <v-list
                  dense
                  flat
                  class="transparent"
                >
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon small class="blue--text">mdi-phone</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="text-body-2">02)521-7505</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon small class="blue--text">mdi-map-marker</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="text-body-2">서울특별시 강남구 역삼로 139</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon small class="blue--text">mdi-email</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="text-body-2">sjwfor@gmail.com</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
      --/ 찾아오시는 길/회사소개 -->

      <!-- * 대문에서만 사용하는 별도의 footer -->
      <v-footer padless dark height="auto" class="ma-0 pa-0">
        <v-card
          flat
          tile
          width="100%"
          style="background-color: #4c5459"
        >
          <v-card-text class="py-12">
            <v-row
              no-gutters
              align="center"
              justify="center"
            >
              <v-col cols="2" class="d-none d-lg-block"></v-col>
              <v-col class="text-left">
                <v-btn small depressed color="grey lighten-5 grey--text text--darken-3"
                  style="cursor: pointer;"
                  @click="goTermsOfUse"
                >
                  이용약관
                </v-btn>
                <v-btn small depressed color="grey lighten-5 grey--text text--darken-3" class="mx-2"
                  style="cursor: pointer;"
                  @click="goPrivacy"
                >
                  개인정보취급방침
                </v-btn>
              </v-col>
              <v-col cols="2" class="d-none d-lg-block"></v-col>
            </v-row>
            <v-row
              no-gutters
              align="center"
              justify="center"
            >
              <v-col cols="2" class="d-none d-lg-block"></v-col>
              <v-col class="text-left pt-4">
                <span>(주)현우엘앤씨/대표:서지원/사업자등록번호 : 206-86-21363/통신판매업신고번호:12-서울강남-20946 서울 강남구 역삼로 140, 409(역삼동,청암빌딩)</span><br>
                <span>T.02-521-7505/F.02-6008-7532/개인정보정책 책임자 : 서지원</span><br>
                <span>(주)현우열앤씨의 모든 프로그램, 상호, 서비스 등은 특허법, 프로그램보호법 등에 의해 보호받고 있습니다.</span>
              </v-col>
              <v-col cols="2" class="d-none d-lg-block"></v-col>
            </v-row>
          </v-card-text>
          <v-btn
            fab
            large
            dark
            top
            right
            absolute
            color="blue-grey"
            @click.prevent="goTopArea"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </v-card>
      </v-footer>
      <!--/ 대문에서만 사용하는 별도의 footer -->

      <!-- <app-footer></app-footer> -->

    </v-dialog>
  </v-row>
</template>

<script>
// import appFooter from '@/components/common/AppFooter'
// import HelloWorld from '../components/HelloWorld'
// import storage from '@/lib/storage'

// 스낵바 에러 메시지 알림을 띄우는 함수다
// const sbpop = msg => {
//   this.$axiosstore.commit('SB_POP', { msg })
// }

export default {
  components: {
    // appFooter
    // HelloWorld
  },
  data: () => ({
    dialog: true,
    // IsTokenValidate: false,
    browserNotChrome: false,
    colors: [
      'indigo',
      'warning',
      'pink darken-2',
      'red lighten-1',
      'deep-purple accent-4'
    ],
    slides: [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth'
    ]
  }),

  mounted () {
    // 중요: 만약 안뜨면 - DOM이 로딩된 후에 다이얼로그를 띄워야 한다!
    // this.dialog = true

    // moment test
    // console.log('moment test:', this.$moment(1562049780 * 1000).format('YYYY-MM-DD HH:mm:ss'))

    // 토큰 저장 유무 테스트
    // console.log('vuex token:', this.$store.state.token)
    // console.log('localStorage token:', localStorage['token'])

    // NOTE: 저장된 토큰이 있는지 유무를 체크(기간 만료를 따지지 않는다. 따져선 안되기 때문)
    // 토큰이 있고 유효하면 - this.IsTokenValidate = true
    // 토큰이 없거나 유효하지 않으면 - this.IsTokenValidate = false
    // if (storage.get('token') && this.$store.state.token) {
    //   // const { data } = this.getTokenValidate(storage.get('token'))
    //   this.getTokenValidate(storage.get('token'))
    //     .then(({ data }) => {
    //       // console.log('IsValidToken : ', data.check)
    //       this.IsTokenValidate = data.check
    //     })
    //     .catch(e => {
    //       sbpop(`토큰 인증 오류. ${e}`)
    //       console.error(e)
    //     })
    // }
    // console.log('here is chrome ? ', this.$browserDetect.isChrome)
    // console.log('LOG: Home > mounted')

    // 중요: 브라우저 체크
    setTimeout(() => {
      if (this.$browserDetect.isIOS) {
        // iOS 인데 크롬이 아니면
        if (!this.$browserDetect.isChromeIOS) {
          this.browserNotChrome = true
        }
      } else {
        // iOS 이외의 운영체제라면
        if (!this.$browserDetect.isChrome) {
          // 크롬이 아니면
          this.browserNotChrome = true
        }
      }
    }, 100)
  },

  computed: {
    // 구분: 로그인 상태인지 정밀하게 체크
    isLogined: function () {
      // console.log('LOG: Home > computed > isLogined')
      if (!this.$store.state.token) return false
      if (!this.$store.state.ui) return false
      if (!this.$store.state.ui.dbcode) return false
      if (!this.$store.state.ui.expDate) return false
      if (this.$store.state.ui.isAgree !== 'Y') return false

      // ? 위를 다 통과해도 this.$store.state.ui.gexp(토큰 만료시각)이 지났으면 false
      if (this.$moment(this.$store.state.ui.gexp * 1000).diff(this.$moment(), 'seconds') <= 0) return false

      return true
    }
  },

  methods: {
    dummy () {
      console.log('dummy dummy ~ ')
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 구분: 로그인 - 백엔드로 구글로그인을 보낸다.
    async logInProc () {
      // NOTE: 모든 로그인에 관련된건 백엔드에서 처리 한다! 이 페이지에서 버튼을 누르면 무조건 백엔드를 거쳐야 한다!
      // this.$router.push('v1/auth/login') // == 에러가 나네?
      // 아래 처럼 절대경로로 줘야 하잖아 쩝.. 3000번 백엔드 주소로 이동해야 하니 build 해야 함!
      const logInUrl = process.env.NODE_ENV !== 'production'
        ? 'http://localhost:3000/v1/auth/login' : 'v1/auth/login'

      window.location.href = logInUrl
      this.dialog = false
    },
    // 구분: 구글 설치페이지 바로가기
    async goGoogleInstall () {
      try {
        const link = document.createElement('a')
        link.href = 'https://www.google.com/chrome/'
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 상단 가격안내 버튼 클릭시 anchor tag 효과로 섹션바로가기
    goPriceBox () {
      try {
        const link = document.createElement('a')
        link.href = '#price_box'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 상단 소개자료 버튼 클릭시 anchor tag 효과로 섹션바로가기
    goCustomCenter () {
      try {
        const link = document.createElement('a')
        link.href = '#customCenter_box'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: footer 의 fab 버튼 클릭시 anchor tag 효과로 섹션바로가기
    goTopArea () {
      try {
        const link = document.createElement('a')
        link.href = '#topArea_box'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 개인정보처리방침
    goPrivacy () {
      // 1) 다른 탭에서 열기
      // let route = this.$router.resolve({ path: '/privacy' })
      // window.open(route.href, '_blank')
      // 현재 path 가 /privacy 가 아니면 이동
      if (this.$router.currentRoute.path !== '/privacy') {
        this.$router.push('/privacy')
      }
    },
    // 구분: 이용약관
    goTermsOfUse () {
      // 현재 path 가 /termsofuse 가 아니면 이동
      if (this.$router.currentRoute.path !== '/termsofuse') {
        this.$router.push('/termsofuse')
      }
    }
  }
}
</script>

<style scoped>
h1 .h1Logo {
  display: block;
  float: left;
  position: relative;
  width: 185px;
  height: 29px;
  background: url("../assets/Home/img/lawork_logo.png") no-repeat left top;
}

/* mainprice */
.mainprice {
  clear: both;
  position: relative;
  background: #eef1f5;
}

.mainprice table {
  border-collapse:collapse;
  border-spacing:0;
  margin:0;
  padding:0;
  border-top: 3px #538be6 solid;
}

#price_box table {
  width: 1200px;
}

#price_box_s table {
  width:100%;
}

.mainprice table tr th {
  height: 60px;
  padding: 11px 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #2c2c2c;
  border-right: 1px #cdcdcd solid; border-bottom: 1px #cdcdcd solid; background: #f8f8f8;
}

.mainprice table tr td {
  padding: 11px 0;
  font-size: 14px;
  text-align: center;
  border-right: 1px #e5e5e5 solid; border-bottom: 1px #e5e5e5 solid;
  background: #FFF;
}

.mainprice table .last{
  border-right: none;
}

.mainprice table tr th:last-child{
  border-right: none;
}

.mainprice table tr td.line{
  border-right: none;
}

.mainprice table tr td.lh{
  line-height: 1.8;
}

.mainprice p {
  color: #3a3b36;
  font-size: 13px;
  line-height: 1.8;
  padding: 30px 0 50px;
}

.mainprice .cmt_s {
  color: #3a3b36;
  font-size: 14px;
  line-height: 1.8;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
</style>
